import { MessageDocument, Repository, UserData, ChatDocument } from "@/types";
import { mapTake } from "@/helpers/utility";
import { FirebaseApp } from "firebase/app";
import { User } from "firebase/auth";
import {
  query,
  collection,
  doc,
  getDocs,
  getDoc,
  updateDoc,
  onSnapshot,
  Timestamp,
  CollectionReference,
  QuerySnapshot,
  limit,
  DocumentData,
  startAfter,
  orderBy,
  QueryDocumentSnapshot,
} from "firebase/firestore";
import { getMaskedMessageDocument } from "@/helpers/messageAbuseRemediate";
import FirestoreReferenceGenerator from "./FirestoreReferenceGenerator.class";
import { useChatStore } from "@/stores/chat";

const LIMIT_MESSAGES_PER_REQUEST = 10;

export class MessageRepository implements Repository {
  private _chatsCollectionRef: CollectionReference<ChatDocument>;
  private _messageUnsubscribeFunction: () => void;
  private _suggestionsUnsubscribeFunction: () => void;
  private _messageProperties = [
    "id",
    "previousMessage",
    "content",
    "type",
    "senderType",
    "senderId",
    "createdAt",
    "deletedAt",
    "attachment",
    "imported",
  ];
  private _suggestionProperties = ["messageId", "suggestion"];
  private takeMessageProperties = mapTake(...this._messageProperties);

  constructor(private _user: User, private _project: FirebaseApp, private _locale: string) {
    this._chatsCollectionRef = new FirestoreReferenceGenerator(this._project, this._locale).getChatsCollectionRef();
  }

  public async dispatch(
    action: "enableListeners" | "disableAllListeners" | "getNewMessages" | "delete" | "onlyDisableAllListeners",
    params: any,
  ): Promise<void> {
    await this[action]?.(params);
  }

  public enableListeners({ chatId }: { chatId: string }): void {
    this._messageUnsubscribeFunction?.();
    useChatStore().currentMessages = [];
    this._messageUnsubscribeFunction = onSnapshot(
      query(this.createMessageRef(chatId), limit(1)),
      this.constructMessageChangeHandler(),
    );
  }

  public enableSuggestionsListener({ chatId }: { chatId: string }) {
    this.disableSuggestionsListener();
    useChatStore().suggestions = [];
    this._suggestionsUnsubscribeFunction = onSnapshot(
      this.createSuggestionQuery(chatId),
      this.constructSuggestionChangeHandler(),
    );
  }

  public disableSuggestionsListener() {
    this._suggestionsUnsubscribeFunction?.();
    useChatStore().suggestions = [];
  }

  public async getNewMessages({ chatId, lastMessage }: { chatId: string; lastMessage: { createdAt: any } }) {
    if (!lastMessage.createdAt) {
      return;
    }
    const messageQuery = query(
      this.createMessageRef(chatId),
      startAfter(lastMessage.createdAt),
      limit(LIMIT_MESSAGES_PER_REQUEST),
    );
    const messageSnapshot = await getDocs(messageQuery);

    messageSnapshot.docs.forEach((doc: QueryDocumentSnapshot) => {
      const documentData = doc.data();
      const maskedDocument = getMaskedMessageDocument(doc.id, documentData);
      const document = { ...this.takeMessageProperties(maskedDocument) } as MessageDocument;
      useChatStore().modifyMessage(document);
    });
    useChatStore().hasBeginningMessage = messageSnapshot.docs.length < LIMIT_MESSAGES_PER_REQUEST;
  }

  public disableAllListeners(): void {
    this.disableSuggestionsListener();
    this._messageUnsubscribeFunction?.();
    useChatStore().currentMessages = [];
  }

  public onlyDisableAllListeners(): void {
    this._messageUnsubscribeFunction?.();
  }

  private createMessageRef(chatId: string) {
    const docRef = doc(this._chatsCollectionRef, chatId);
    return query(collection(docRef, "messages"), orderBy("createdAt", "desc"));
  }

  private createSuggestionQuery(chatId: string) {
    return collection(doc(this._chatsCollectionRef, chatId), "suggestions");
  }

  private constructMessageChangeHandler() {
    return (snapshot: QuerySnapshot) => {
      snapshot.docChanges().forEach((docChange) => {
        const documentData = docChange.doc.data();
        const maskedDocument = getMaskedMessageDocument(docChange.doc.id, documentData);
        const document = { ...this.takeMessageProperties(maskedDocument) } as MessageDocument;

        switch (docChange.type) {
          case "added":
            useChatStore().addMessage(document);
            break;
          case "modified":
            useChatStore().modifyMessage(document);
            break;
          case "removed":
            !docChange.doc.exists() && useChatStore().removeMessage(document);
            break;
          default:
            break;
        }
      });
    };
  }

  private constructSuggestionChangeHandler() {
    return (snapshot: QuerySnapshot) => {
      snapshot.docChanges().forEach((docChange: any) => {
        const takeSuggestionProperties = mapTake(...this._suggestionProperties);
        const document = {
          id: docChange.doc.id,
          locale: this._locale,
          ...takeSuggestionProperties(docChange.doc.data()),
        };

        switch (docChange.type) {
          case "added":
            useChatStore().addSuggestion(document);
            break;
          case "modified":
            useChatStore().updateSuggestion(document);
            break;
          case "removed":
            useChatStore().removeSuggestion(document);
            break;
          default:
            break;
        }
      });
    };
  }

  public async delete({
    chatId,
    id,
    user,
    reason,
  }: {
    chatId: string;
    id: string;
    user: UserData;
    reason: string;
  }): Promise<void> {
    const chatDocumentRef = doc(this._chatsCollectionRef, chatId);
    const chat: DocumentData = await getDoc(chatDocumentRef);
    const content = "This message has been deleted";
    const deletedAt = Timestamp.now();
    const deleted = {
      by: user.uid,
      reason: reason,
    };

    if (chat.exists() && chat.data().lastMessage.id === id) {
      await updateDoc(chatDocumentRef, {
        "lastMessage.content": content,
        "lastMessage.deletedAt": deletedAt,
        "lastMessage.deleted": deleted,
      } as any);
    }

    const chatsCollection = collection(doc(this._chatsCollectionRef, chatId), "messages");
    await updateDoc(doc(chatsCollection, id), {
      content: "This message has been deleted",
      deletedAt: Timestamp.now(),
      deleted: deleted,
    });
  }
}
